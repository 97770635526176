import gsap from 'gsap';

export default class AppointmentWindow {

    constructor() {

        this.opened = false;
        this.element = $('.appointment').e();

    }

    load() {
        return new Promise(resolve => {

            let timer;
        
            const iFrame = document.querySelector('iframe');
        
            const onIframeLoad = () => {
                if (timer) clearTimeout(timer);
                resolve();
            }
        
            timer = setTimeout(() => {
                if (iFrame) iFrame.removeEventListener('load', onIframeLoad);
                resolve();
            }, 2500);
        
            if (iFrame) iFrame.addEventListener('load', onIframeLoad);
        
        });
    }

    open() {

        if (this.opened) return;

        this.opened = true;

        this.shade = $('<div class="appointment-shade"></div>');

        $(this.element).insert(this.shade, 'beforeBegin');

        const tl = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
        tl.to(this.shade.e(), { opacity: .85, duration: .5 });
        tl.to(this.element, { x: 0, duration: .7 }, '-=.5');
        tl.eventCallback('onComplete', () => $(this.element).addClass('open'));

    }

    close() {

        if (!this.opened) return;

        this.opened = false;
        $(this.element).removeClass('open');
        
        const tl = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
        tl.to(this.element, { x: '-105%', duration: .7 });
        tl.to(this.shade.e(), { opacity: 0, duration: .5 }, '-=.5');
        

    }

    mount() {
        $('a[href$="appointment"]').prevent('click', () => this.open());
        $('.appointment__close').click(() => this.close());
    }

}