import { Loader } from "@googlemaps/js-api-loader";
import config from './config';

export default class Map {

    constructor(selector) {
        this.container = $(selector).e();
        this.window = $(`${selector}-window`);
    }

    async load() {

        const loader = new Loader({
            apiKey: config.map.api_key,
            version: "weekly",
        });

        await loader.load();

        this.map = new google.maps.Map(this.container, {
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            center: config.map.center,
            zoom: 15,
            mapTypeId: 'roadmap',
            styles: config.map.theme
        });

        this.placeMarker();

    }

    placeMarker() {

        this.marker = new google.maps.Marker({
            position: config.map.center,
            map: this.map,
            title: "Magnolia Nails & iLashes",
        });

        this.marker.addListener("click", () => this.openAddressWindow());

    }

    openAddressWindow() {

        this.window.addClass('active');

        const close = this.window.children('.map__window-close');

        close.click(() => {
            this.window.removeClass('active');
            close.kill();
        });

    }

}