const mountNav = () => {

    const navbar = $('.navbar')

    $('.navbar__icon, .navbar a').click(() => {
        navbar.toggle('open');
    })

}

export default mountNav;