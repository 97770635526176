// Import Runtime
import 'regenerator-runtime/runtime';

// Import Dreams JS and set Global Scope
import './Dreams';
import Map from './Map';
import AppointmentWindow from './Appointment';
import mountModals from './modals';
import mountNav from './navbar';

import gsap from 'gsap';


const imageLoad = async () => {

    const images = $('img').map(img =>  new Promise(resolve => {
        if (img.complete && img.naturalHeight !== 0) return resolve();
        img.addEventListener('load', resolve);
    }));

    await Promise.all(images);

}


const removePreloader = () => {

    const tl = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
    const pl = $('.preloader');

    setTimeout(() => pl.addClass('complete'), 800);

    tl.to('.preloader__loader', { opacity: 0, duration: .6 });

    tl.to(pl.e(), { opacity: 0, duration: .6 });

    tl.eventCallback('onComplete', () => pl.remove());

}


window.addEventListener('DOMContentLoaded', async () => {

    gsap.to(document.body, { opacity: 1, duration: .6 });

    const map = new Map('#map');
    const appointmentWindow = new AppointmentWindow();

    await Promise.all([map.load(), appointmentWindow.load(), imageLoad()]);

    removePreloader();

    appointmentWindow.mount();

    mountModals();

    mountNav();

});