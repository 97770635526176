import gsap from 'gsap';


class Modal {

    constructor(element) {
        this.opened = false;
        this.id = element.id;
        this.element = $(element);
    }

    mount() {

        $(`a[href~="#${this.id}"]`).prevent('click', () => this.open());
        this.element.children('.modal__close').click(() => this.close());

    }

    open() {

        if (this.opened) return;

        this.opened = true;

        this.shade = $('<div class="appointment-shade"></div>');

        this.element.insert(this.shade, 'beforeBegin');
        
        const tl = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
        tl.to(this.shade.e(), { opacity: .85, duration: .5 });
        tl.to(this.element.e(), { x: 0, duration: .7 }, '-=.5');
        tl.eventCallback('onComplete', () => this.element.addClass('open'));


    }

    close() {

        if (!this.opened) return;

        this.opened = false;
        this.element.removeClass('open');
        
        const tl = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
        tl.to(this.element.e(), { x: '-105%', duration: .7 });
        tl.to(this.shade.e(), { opacity: 0, duration: .5 }, '-=.5');

    }

}




export default () => {

    $('.modal').forEach(modal => new Modal(modal).mount());

}